/**
 * Card
 */

.card .card-header {
  padding: 5px 12px 5px 12px;
  font-size: 13px;
}

.card .card-body {
  padding: 5px 12px 5px 12px;
}

.card .card-header .btn {
  padding-top: 0px;
  padding-bottom: 0px;
  border: 0px;
}

.card .card-header .btn svg {
  height: 12px;
  margin: 0px;
  padding: 0px;
}

.card .card-body h6 {
  font-size: 14px;
  font-weight: bold;
  padding-top: 2px;
}

.card .card-body {
  margin-bottom: 6px;
}

/**
 * Properties
 */

.properties {
  width: 100%;
  border-collapse: separate;
  border-spacing: 3px;
}

.prop-name-column {
  font-size: 13px;
  text-align: right;
  padding-right: 10px;
  word-wrap: break-word;
  max-width: 1px;
}

.prop-value-column {
  background: #e8e8e8;
  padding: 2px 5px 2px 10px;
  border-radius: 3px;
  font-size: 13px;
  word-wrap: break-word;
  max-width: 1px;
}
