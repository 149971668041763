.explore-preview-graph {
  width: 100%;
  height: 100%;
  border: 1px solid #e0e3eb;
  border-radius: 3px;
}

.vis-network:focus {
  outline: none;
}
